/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { resolveImage, resolveCB, resolveAboveCta } from "@dataResolvers";
import { Page as PageTemplate } from "@pageTemplates";
import { t } from "@utils";
import QueryContainer from "@templates/QueryContainer";

export const query = graphql`
  query EventsQuery($uri: [String], $siteId: [Craft_QueryArgument]) {
    craft {
      entry(section: "events", uri: $uri, siteId: $siteId) {
        lang: language

        title
        ... on Craft_events_event_Entry {
          heroTitle
          entrySubtitle
          contentBuilder {
            ...ContentBuilderFragment
          }
          metaDescription
          metaFeaturedImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
        next {
          id
          ... on Craft_events_event_Entry {
            title
            url
            metaDescription
            metaFeaturedImage(withTransforms: ["aboveFooterCtaExtraLarge"]) {
              width(transform: "aboveFooterCtaExtraLarge")
              height(transform: "aboveFooterCtaExtraLarge")
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url(transform: "aboveFooterCtaExtraLarge")
            }
          }
        }
      }
      index: entry(section: "eventsIndex", siteId: $siteId) {
        title
        ... on Craft_eventsIndex_eventsIndex_Entry {
          heroTitle
          entrySubtitle
          aboveFooterCtaTitle
          aboveFooterCtaSubtitle
          url
          #Meta
          metaDescription
          metaFeaturedImage(withTransforms: ["aboveFooterCtaExtraLarge"]) {
            width(transform: "aboveFooterCtaExtraLarge")
            height(transform: "aboveFooterCtaExtraLarge")
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url(transform: "aboveFooterCtaExtraLarge")
          }
        }
      }
      entries(section: "events", limit: 1, siteId: $siteId) {
        title
        ... on Craft_events_event_Entry {
          title
          url
          metaDescription
          metaFeaturedImage(withTransforms: ["aboveFooterCtaExtraLarge"]) {
            width(transform: "aboveFooterCtaExtraLarge")
            height(transform: "aboveFooterCtaExtraLarge")
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url(transform: "aboveFooterCtaExtraLarge")
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry, entries, index }) => {
  if (!entry) return null;
  const {
    title,
    heroTitle,
    entrySubtitle,
    contentBuilder,
    metaFeaturedImage,
    metaDescription,
    next,
    lang,
  } = entry;

  const firstEntry = entries?.map(e => {
    return {
      ...e,
      subtitle: e?.title,
      title: t("Next Event", lang),
    };
  })[0];
  const nextEntry = {
    ...next,
    subtitle: next?.title,
    title: t("Next Event", lang),
  };

  const left = {
    title: index?.aboveFooterCtaTitle || index?.title,
    subtitle: index?.aboveFooterCtaSubtitle || index?.entrySubtitle,
    url: index?.url,
    metaFeaturedImage: index?.metaFeaturedImage,
  };
  const right = next ? nextEntry : firstEntry;

  const cards = [left, right];

  return {
    title,
    hero: {
      title: heroTitle || title,
      subtitle: entrySubtitle,
      image: resolveImage(metaFeaturedImage),
    },
    sections: resolveCB(contentBuilder),
    cards: resolveAboveCta(cards),
    meta: {
      title,
      metaFeaturedImage: resolveImage(metaFeaturedImage),
      metaDescription,
    },
  };
};

const Page = ({ data, pageContext }) => (
  <QueryContainer
    data={data}
    pageContext={pageContext}
    PageTemplate={PageTemplate}
    dataResolver={dataResolver}
  />
);

export default Page;
